import {
  Children,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// Components
import Button from "../shared/Button";

// Styles
import styles from "../../styles/Carousel.module.css";

// Icons
import { VscChevronLeft, VscChevronRight } from "react-icons/vsc";

const Carousel = ({ children }) => {
  const [current, setCurrent] = useState(1);
  const [translateX, setTranslateX] = useState(0);

  const transitionDuration = "400ms";

  const containerRef = useRef();

  const actionHandler = mode => {
    containerRef.current.style.transitionDuration = transitionDuration;

    if (mode === "prev") {
      if (current <= 1) {
        setTranslateX(0);
        setCurrent(children.length);
      } else {
        setTranslateX(containerRef.current.clientWidth * (current - 1));
        setCurrent(prev => --prev);
      }
    } else if (mode === "next") {
      if (current >= children.length) {
        setTranslateX(containerRef.current.clientWidth * (children.length + 1));
        setCurrent(1);
      } else {
        setTranslateX(containerRef.current.clientWidth * (current + 1));
        setCurrent(prev => ++prev);
      }
    }
  };

  // Handles infinite scroll smooth effect
  useEffect(() => {
    const transitionEnd = () => {
      if (current <= 1) {
        containerRef.current.style.transitionDuration = "0ms";
        setTranslateX(containerRef.current.clientWidth * current);
      }

      if (current >= children.length) {
        containerRef.current.style.transitionDuration = "0ms";
        setTranslateX(containerRef.current.clientWidth * children.length);
      }
    };

    document.addEventListener("transitionend", transitionEnd);

    return () => {
      document.removeEventListener("transitionend", transitionEnd);
    };
  }, [current, children]);

  // Handles autoplay
  useEffect(() => {
    const autoplay = setInterval(() => {
      actionHandler("next");
    }, 3000);
    return () => clearInterval(autoplay);
  }, [actionHandler]);

  const slides = useMemo(() => {
    if (children.length > 1) {
      let items = Children.map(children, (child, index) => (
        <li key={index} className={styles.Slide}>
          {child}
        </li>
      ));

      return [
        <li key={children.length + 1} className={styles.Slide}>
          {children[children.length - 1]}
        </li>,
        ...items,
        <li key={children.length + 2} className={styles.Slide}>
          {children[0]}
        </li>,
      ];
    }

    return <li className={styles.Slide}>{children[0]}</li>;
  }, [children]);

  useLayoutEffect(() => {
    setTranslateX(containerRef.current.clientWidth * current);
  }, []);

  return (
    <section className={`${styles.Root} rounded-xl max-w-full`}>
      <ul
        ref={containerRef}
        className={styles.Container}
        style={{
          transform: `translateX(${-translateX}px)`,
          transitionDuration: transitionDuration,
          transitionTimingFunction: "ease-in-out",
        }}
      >
        {slides}
      </ul>
      <div className="mt-3 flex gap-2 absolute top-1 right-3 sm:top-2 sm:right-5 lg:top-3 lg:right-7">
        <div onClick={() => actionHandler("prev")}>
          <Button rounded variant="contained">
            <VscChevronLeft />
          </Button>
        </div>
        <div onClick={() => actionHandler("next")}>
          <Button rounded variant="contained">
            <VscChevronRight />
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Carousel;
