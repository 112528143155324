import { useContext, useEffect, useState } from "react";

// React-router-dom
import { ScrollRestoration } from "react-router-dom";

// Context
import GuestQuestionnaireContext from "../../context/GuestQuestionnaireContext";

// Components
import Button from "../shared/Button";
import Pagination from "./Pagination";
import TrueAndFalse from "./shared/TrueAndFalse";
import Ratings from "./shared/Ratings";
import Comment from "./shared/Comment";
// Toast
import { toast } from "react-toastify";

const questions = [
  {
    id: "b1",
    type: "rating",
    category: "reception and service",
    question: "Courtesy and friendliness of reception/concierge staff",
  },
  {
    id: "b2",
    type: "rating",
    category: "reception and service",
    question: "Check-in process",
  },
  {
    id: "b3",
    type: "rating",
    category: "reception and service",
    question: "Check-out process",
  },
  {
    id: "b4",
    type: "comment",
    category: "reception and service",
  },
  {
    id: "b5",
    type: "rating",
    category: "guest room",
    question: "Room cleanliness",
  },
  {
    id: "b6",
    type: "rating",
    category: "guest room",
    question: "Bathroom cleanliness",
  },
  {
    id: "b7",
    type: "rating",
    category: "guest room",
    question: "Amenities",
  },
  {
    id: "b8",
    type: "rating",
    category: "guest room",
    question: "Quality of sleep",
  },
  {
    id: "b9",
    type: "trueAndFalse",
    category: "guest room",
    question: "Was everything in working order in your room?",
  },
  {
    id: "b10",
    type: "comment",
    category: "guest room",
  },
  {
    id: "b11",
    type: "rating",
    category: "facilities",
    question: "Swimming Pool",
  },
  {
    id: "b12",
    type: "rating",
    category: "facilities",
    question: "Lush Cafe",
  },
  {
    id: "b13",
    type: "comment",
    category: "facilities",
  },
];

const SectionTwo = () => {
  const { completeFormData, prevSection, nextSection, addData } = useContext(
    GuestQuestionnaireContext
  );

  const [formData, setFormData] = useState();

  useEffect(() => {
    if (completeFormData["2"]) {
      setFormData(completeFormData["2"]);
    } else
      setFormData({
        b1: null,
        b2: null,
        b3: null,
        b4: "",
        b5: "",
        b6: "",
        b7: "",
        b8: "",
        b9: null,
        b10: "",
        b11: "",
        b12: "",
        b13: "",
      });
  }, [completeFormData]);

  const optional = ["b4", "b10", "b13"];

  const handleChange = (id, answer) => e => {
    setFormData(prev => {
      // Comment textarea
      if (e.target.nodeName === "TEXTAREA") {
        return { ...prev, [e.target.id]: e.target.value };
      }

      // Rating button
      if (e.target.nodeName === "BUTTON") {
        return { ...prev, [id]: answer };
      }

      // True and False radio
      if (e.target.type === "radio") {
        return { ...prev, [id]: answer };
      }
    });
  };

  const handlePrevBtn = () => {
    prevSection();
  };

  const handleNextBtn = () => {
    const isFilled = prop =>
      optional.includes(prop) ||
      (formData[prop] !== null && formData[prop] !== "");

    const allFilled = Object.keys(formData).every(x => isFilled(x));

    if (!allFilled) {
      toast.error("Please fill everything");
      return;
    }

    addData(2, formData);
    nextSection();
  };

  return (
    <div className="py-8">
      <ScrollRestoration />
      <p className="text-neutral-500">
        On a scale of 1 (low) to 5 (high), please rate the service that you
        received
      </p>
      <div className="flex flex-col gap-6 py-6">
        {/* Reception and Service */}
        <div>
          <h2 className="text-neutral-600 font-medium border-b-[1px] border-deepOrange-700 pb-2">
            Reception and Service
          </h2>
          <div className="py-4 flex flex-col gap-3">
            {formData &&
              questions
                .filter(q => q.category === "reception and service")
                .map(question => {
                  if (question.type === "rating")
                    return (
                      <Ratings
                        key={question.id}
                        questionData={question}
                        formData={formData}
                        onChange={handleChange}
                      />
                    );

                  if (question.type === "comment")
                    return (
                      <Comment
                        key={question.id}
                        questionData={question}
                        formData={formData}
                        onChange={handleChange}
                      />
                    );
                })}
          </div>
        </div>
        {/* Guest Room */}
        <div>
          <h2 className="text-neutral-600 font-medium border-b-[1px] border-deepOrange-700 pb-2">
            Guest Room
          </h2>
          <div className="py-4 flex flex-col gap-4">
            {formData &&
              questions
                .filter(q => q.category === "guest room")
                .map(question => {
                  if (question.type === "rating")
                    return (
                      <Ratings
                        key={question.id}
                        questionData={question}
                        formData={formData}
                        onChange={handleChange}
                      />
                    );
                  if (question.type === "trueAndFalse") {
                    return (
                      <TrueAndFalse
                        key={question.id}
                        questionData={question}
                        formData={formData}
                        onChange={handleChange}
                      />
                    );
                  }
                  if (question.type === "comment")
                    return (
                      <Comment
                        key={question.id}
                        questionData={question}
                        formData={formData}
                        onChange={handleChange}
                      />
                    );
                })}
          </div>
        </div>
        {/* Facilities */}
        <div>
          <h2 className="text-neutral-600 font-medium border-b-[1px] border-deepOrange-700 pb-2">
            Facilities
          </h2>
          <div className="py-4 flex flex-col gap-4">
            {formData &&
              questions
                .filter(q => q.category === "facilities")
                .map(question => {
                  if (question.type === "rating")
                    return (
                      <Ratings
                        key={question.id}
                        questionData={question}
                        formData={formData}
                        onChange={handleChange}
                      />
                    );
                  if (question.type === "comment")
                    return (
                      <Comment
                        key={question.id}
                        questionData={question}
                        formData={formData}
                        onChange={handleChange}
                      />
                    );
                })}
          </div>
        </div>
      </div>
      {/* Footer */}
      <div className="relative h-8 my-8">
        <div className="absolute left-0" onClick={handlePrevBtn}>
          <Button variant="text">
            <span className="flex items-center text-deepOrange-600 text-sm">
              Previous
            </span>
          </Button>
        </div>
        <Pagination />
        <div className="absolute right-0" onClick={handleNextBtn}>
          <Button variant="text">
            <span className="flex items-center text-deepOrange-600 text-sm">
              Next
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
