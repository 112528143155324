import { useState, useContext } from "react";

// Context
import GuestQuestionnaireContext from "../../context/GuestQuestionnaireContext";

// Components
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";

const pages = [
  <SectionOne />,
  <SectionTwo />,
  <SectionThree />,
  <SectionFour />,
];

const Main = () => {
  const { currentSection, handleSubmit } = useContext(
    GuestQuestionnaireContext
  );

  return <form onSubmit={handleSubmit}>{pages[currentSection]}</form>;
};

export default Main;
