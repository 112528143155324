import { useContext, useState, forwardRef, useEffect } from "react";

// React-router-dom
import { ScrollRestoration } from "react-router-dom";

// Context
import GuestQuestionnaireContext from "../../context/GuestQuestionnaireContext";

// Component
import Button from "../shared/Button";
import Pagination from "./Pagination";
import MultipleChoice from "./shared/MultipleChoice";

// React-datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// Toast
import { toast } from "react-toastify";

const questions = [
  {
    id: "a1",
    type: "multipleChoice",
    question: "How did you find out about our hotel?",
    answers: [
      "Friend / acquaintance",
      "Internet (hotel website, facebook, etc)",
      "Travel Agency or Catalogue",
      "Online Travel Agent (Agoda, Booking, Trip.com)",
    ],
  },
  {
    id: "a2",
    type: "multipleChoice",
    question: "What was the purpose of your visit?",
    answers: ["Business", "Conference", "Event / Wedding", "Leisure / Travel"],
  },
  {
    id: "a3",
    type: "multipleChoice",
    question: "Why did you choose this hotel?",
    answers: [
      "Have stayed here before",
      "Recommended to me",
      "Price",
      "Location",
      'General "look" of hotel',
    ],
  },
];

const SectionOne = () => {
  const { completeFormData, nextSection, addData } = useContext(
    GuestQuestionnaireContext
  );

  const [formData, setFormData] = useState();

  useEffect(() => {
    if (completeFormData["1"]) {
      setFormData(completeFormData["1"]);
    } else
      setFormData({
        name: "",
        roomNo: "",
        arrivalDate: new Date(),
        departureDate: new Date(),
        a1: "",
        a2: "",
        a3: "",
      });
  }, [completeFormData]);

  const handleChange = (id, answer) => e => {
    setFormData(prev => {
      // Multiple choice radio
      if (e.target.type === "radio") {
        return { ...prev, [id]: answer };
      }

      // Other input
      if (e.target.id.startsWith("other")) {
        return { ...prev, [id]: e.target.value };
      }

      // Basic input
      return { ...prev, [e.target.id]: e.target.value };
    });
  };

  const handleNextBtn = () => {
    const isFilled = Object.values(formData).every(x => x !== null && x !== "");

    if (!isFilled) {
      toast.error("Please fill everything");
      return;
    }

    addData(1, {
      ...formData,
      arrivalDate: formData.arrivalDate.toDateString(),
      departureDate: formData.departureDate.toDateString(),
    });
    nextSection();
  };

  return (
    <div className="py-6">
      <ScrollRestoration />
      {formData && (
        <>
          <div className="grid gap-x-3 gap-y-5 grid-cols-4">
            <div className="flex flex-col gap-1 col-span-3">
              <label htmlFor="name" className="text-neutral-500 text-xs">
                Full Name
              </label>
              <input
                id="name"
                type="text"
                className="border-[1px] border-neutral-200 outline-none px-2 py-1 rounded-md"
                value={formData.name}
                onChange={handleChange(undefined)}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="roomNo" className="text-neutral-500 text-xs">
                Room No.
              </label>
              <input
                id="roomNo"
                type="text"
                maxLength="4"
                className="border-[1px] border-neutral-200 outline-none px-2 py-1 rounded-md"
                value={formData.roomNo}
                onChange={handleChange(undefined)}
              />
            </div>
            <div className="flex flex-col gap-2 col-span-2">
              <label htmlFor="arrivalDate" className="text-neutral-500 text-xs">
                Arrival Date
              </label>
              <DatePicker
                id="arrivalDate"
                customInput={<CustomDatePicker />}
                dateFormat="MMMM d, yyyy"
                selected={formData.arrivalDate}
                selectsStart
                startDate={formData.arrivalDate}
                endDate={formData.departureDate}
                onChange={date => {
                  setFormData(prev => ({ ...prev, arrivalDate: date }));
                }}
              />
            </div>
            <div className="flex flex-col gap-2 col-span-2">
              <label
                htmlFor="departureDate"
                className="text-neutral-500 text-xs"
              >
                Departure Date
              </label>
              <DatePicker
                customInput={<CustomDatePicker />}
                dateFormat="MMMM d, yyyy"
                selected={formData.departureDate}
                selectsEnd
                startDate={formData.arrivalDate}
                endDate={formData.departureDate}
                minDate={formData.arrivalDate}
                onChange={date => {
                  setFormData(prev => ({ ...prev, departureDate: date }));
                }}
              />
            </div>
          </div>
          <div className="mt-8 flex flex-col gap-6">
            {questions
              ?.filter(q => q.type === "multipleChoice")
              .map(question => (
                <MultipleChoice
                  key={question.id}
                  questionData={question}
                  formData={formData}
                  onChange={handleChange}
                />
              ))}
          </div>
        </>
      )}
      {/* Footer */}
      <div className="relative h-8 my-8">
        <div className="absolute right-0" onClick={handleNextBtn}>
          <Button variant="text">
            <span className="flex items-center text-deepOrange-600 text-sm">
              Next
            </span>
          </Button>
        </div>
        <Pagination />
      </div>
    </div>
  );
};

export default SectionOne;

const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
  <div
    className="w-full text-center text-sm bg-deepOrange-600 p-2 font-medium rounded-md text-deepOrange-100 hover:cursor-pointer hover:bg-deepOrange-700"
    onClick={onClick}
    ref={ref}
  >
    {value}
  </div>
));
