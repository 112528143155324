// React-router-dom
import { Link } from "react-router-dom";

// Components
import Layout from "../components/Layout";

// Icons
import { VscChevronRight } from "react-icons/vsc";
import {
  PiClockBold,
  PiClockAfternoonBold,
  PiCoffeeBold,
  PiBowlFoodBold,
} from "react-icons/pi";

const FacilitiesPage = () => {
  return (
    <Layout title="Our Facilities">
      <div className="px-4 pb-8 max-w-3xl mx-auto bg-[#fafafa] ">
        <div className="text-sm py-3 text-neutral-600 flex gap-1 items-center">
          <Link to="/">
            <p className="hover:underline hover:text-deepOrange-700">Home</p>
          </Link>
          <VscChevronRight />
          <p className="font-medium">Facilities</p>
        </div>
        <div className="mt-4">
          <h1 className="text-2xl font-medium text-neutral-700">
            Our Facilities
          </h1>
          <p className="mt-1 text-neutral-600">
            We are dedicated to ensuring your stay is comfortable and enjoyable
            by providing a range of convenient facilities to enhance your
            experience.
          </p>
          <div className="mt-10 flex flex-col gap-14">
            <FacilityCard
              title="Swimming Pool"
              description={[
                "Take a refreshing dip or simply lounge by our inviting swimming pool, nestled in the serene garden area.",
                "We have fresh towels available for you by the pool.",
              ]}
              image="/images/facilities/swimming-pool.jpg"
              openingTime="8 a.m."
              closingTime="7 p.m."
            />
            <FacilityCard
              title="Lush Cafe"
              description={[
                "Here, guests can relax and unwind with a perfectly brewed cup of coffee in a space designed for tranquility.",
                "Wi-fi is also available in the cafe area.",
              ]}
              image="/images/facilities/lush-cafe.jpg"
              openingTime="8 a.m."
              closingTime="5 p.m."
            />
            <FacilityCard
              title="Mae Sa Restaurant"
              description={[
                "Breakfast starts at 6 a.m. for our hotel guests. Don't forget to bring your breakfast coupons and present it to our staff.",
                "Mae Sa Restaurant is also open for International Lunch Buffet. For prices please don't hesitate to contact our front office staff.",
              ]}
              image="/images/facilities/mae-sa.jpg"
              breakfastTime="6 a.m. - 10 a.m."
              lunchTime="11 a.m. - 1 p.m."
            />
            <FacilityCard
              title="Huay Kaew Coffee Shop"
              description={[
                "If you're in need of a chic and spacious venue for a conference meeting or dinner, consider our Huay Kaew Coffee Shop. With capacity for up to 100 guests, this stylish cafe serves dinner sets and offers a lunch buffet option for conferences.",
              ]}
              image="/images/facilities/huay-kaew.jpg"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FacilitiesPage;

const FacilityCard = ({
  title,
  description,
  image,
  openingTime,
  closingTime,
  breakfastTime,
  lunchTime,
}) => {
  return (
    <div>
      <img
        src={image}
        alt="Swimming pool"
        className="rounded-lg drop-shadow-md"
      />
      <h2 className="mt-5 font-medium text-xl text-neutral-700">{title}</h2>
      {description.map((item, index) => (
        <p key={index} className="mt-2 text-neutral-600">
          {item}
        </p>
      ))}
      <div className="mt-3 flex flex-col gap-2">
        {openingTime && closingTime && (
          <>
            <div className="flex justify-between bg-deepOrange-100 px-3 py-1 rounded-full">
              <p className="text-deepOrange-700 flex items-center gap-1">
                <PiClockBold className="inline" />
                Opening time
              </p>
              <p className="text-deepOrange-700">{openingTime}</p>
            </div>
            <div className="flex justify-between bg-deepOrange-100 px-3 py-1 rounded-full">
              <p className="text-deepOrange-700 flex items-center gap-1">
                <PiClockAfternoonBold className="inline" /> Closing time
              </p>
              <p className="text-deepOrange-700">{closingTime}</p>
            </div>
          </>
        )}
        {breakfastTime && (
          <div className="flex justify-between bg-deepOrange-100 px-3 py-1 rounded-full">
            <p className="text-sm text-deepOrange-700 flex items-center gap-1">
              <PiCoffeeBold className="inline" />
              Breakfast Buffet
            </p>
            <p className="text-sm text-deepOrange-700">{breakfastTime}</p>
          </div>
        )}
        {lunchTime && (
          <div className="flex justify-between bg-deepOrange-100 px-3 py-1 rounded-full">
            <p className="text-sm text-deepOrange-700 flex items-center gap-1">
              <PiBowlFoodBold className="inline" />
              Lunch Buffet
            </p>
            <p className="text-sm text-deepOrange-700">{lunchTime}</p>
          </div>
        )}
      </div>
    </div>
  );
};
