import { Link } from "react-router-dom";

// Components
import Layout from "../components/Layout";
import Button from "../components/shared/Button";

const ErrorPage = () => {
  return (
    <Layout title="404 Error Page">
      <div className="min-h-[80vh] flex flex-col justify-center items-center gap-3">
        <p className="text-deepOrange-700 font-medium text-3xl">404</p>
        <p>This page does not exist</p>
        <Link to="/">
          <Button>Go back to homepage</Button>
        </Link>
      </div>
    </Layout>
  );
};

export default ErrorPage;
