// React-router-dom
import { Link } from "react-router-dom";

// Icons
import { VscChevronRight } from "react-icons/vsc";

const Card = ({ content }) => {
  const { title, description, imageUrl, btn, btnLink } = content;

  return (
    <div
      className={`h-full w-full bg-cover bg-center relative overflow-hidden before:content-[''] before:w-full before:h-full before:inset-0 before:absolute before:bg-gradient-to-t before:from-neutral-700/70 before:to-deepOrange-400/25 flex flex-col justify-end drop-shadow-md px-4 py-5 md:px-6 md:py-8 lg:px-10 lg:py-12`}
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <div className="z-50 flex flex-col items-start sm:gap-1">
        <h2 className="text-neutral-100 text-lg font-semibold sm:text-xl xl:text-2xl">
          {title}
        </h2>
        <p className="text-sm text-neutral-200 max-w-md sm:text-base">
          {description}
        </p>
        {btn && btnLink && (
          <Link to={btnLink}>
            <button className="mt-[10px] bg-deepOrange-100/90 pl-3 pr-2 py-1 rounded-[4px]">
              <p className="text-sm text-deepOrange-700 sm:text-base">
                {btn} <VscChevronRight className="inline" />
              </p>
            </button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Card;
