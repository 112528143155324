const Comment = ({ questionData, formData, onChange }) => {
  const { id } = questionData;
  return (
    <div>
      <h3>Comments:</h3>
      <textarea
        id={id}
        value={formData[id]}
        className="w-full border-[1px] rounded-md h-28 max-h-28 resize-none outline-none p-2"
        onChange={onChange(undefined)}
      />
    </div>
  );
};

export default Comment;
