import { forwardRef, useState } from "react";
import { Link, ScrollRestoration } from "react-router-dom";

// Context
import { GuestQuestionnaireProvider } from "../context/GuestQuestionnaireContext";

// Components
import Layout from "../components/Layout";
import Main from "../components/guest-questionnaire/Main";

// Icons
import { VscChevronRight } from "react-icons/vsc";

// Packages
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GuestQuestionnairePage = () => {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <Layout title="Guest Questionnaire">
      <div className="px-4 pb-8 max-w-3xl mx-auto">
        <div className="text-sm py-3 text-neutral-600 flex gap-1 items-center">
          <Link to="/">
            <p className="hover:underline hover:text-deepOrange-700">Home</p>
          </Link>
          <VscChevronRight />
          <p className="font-medium">Guest Questionnaire</p>
        </div>
        {currentPage === 1 && (
          <header className="mt-4">
            <h1 className="text-2xl font-medium text-neutral-700">
              Guest Questionnaire
            </h1>
            <p className="mt-2 text-neutral-500">
              Your feedback means a lot of us. Let us know how we can improve.
            </p>
          </header>
        )}
        <GuestQuestionnaireProvider>
          <Main />
        </GuestQuestionnaireProvider>
        <ToastContainer />
      </div>
    </Layout>
  );
};

export default GuestQuestionnairePage;

const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
  <div
    className="w-full text-center text-sm bg-deepOrange-600 p-2 font-medium rounded-md text-deepOrange-100 hover:cursor-pointer hover:bg-deepOrange-700"
    onClick={onClick}
    ref={ref}
  >
    {value}
  </div>
));

const FirstPage = () => {
  const multipleChoice = [
    {
      question: "How did you find out about our hotel?",
      answers: [
        "Friend / acquaintance",
        "Internet (hotel website, facebook, etc)",
        "Travel Agency or Catalogue",
        "Online Travel Agent (Agoda, Booking, Trip.com)",
      ],
    },
    {
      question: "What was the purpose of your visit?",
      answers: [
        "Business",
        "Conference",
        "Event / Wedding",
        "Leisure / Travel",
      ],
    },
    {
      question: "Why did you choose this hotel?",
      answers: [
        "Have stayed here before",
        "Recommended to me",
        "Price",
        "Location",
        'General "look" of hotel',
      ],
    },
  ];

  const [formData, setFormData] = useState({
    name: "",
    roomNo: "",
    arrivalDate: new Date(),
    departureDate: new Date(),
  });

  const handleChange = e => {
    setFormData(prev => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleMultipleChoice = e => {
    setFormData(prev => ({
      ...prev,
      multipleChoice: {
        ...prev.multipleChoice,
        [e.target.name]: e.target.value,
      },
    }));
  };

  return (
    <div className="py-6">
      <ScrollRestoration />
      <div className="grid gap-x-3 gap-y-5 grid-cols-4">
        <div className="flex flex-col gap-1 col-span-3">
          <label htmlFor="name" className="text-neutral-500 text-xs">
            Full Name
          </label>
          <input
            id="name"
            type="text"
            className="border-[1px] border-neutral-200 outline-none px-2 py-1 rounded-md"
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label htmlFor="roomNo" className="text-neutral-500 text-xs">
            Room No.
          </label>
          <input
            id="roomNo"
            type="text"
            maxLength="4"
            className="border-[1px] border-neutral-200 outline-none px-2 py-1 rounded-md"
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col gap-2 col-span-2">
          <label htmlFor="arrivalDate" className="text-neutral-500 text-xs">
            Arrival Date
          </label>
          <DatePicker
            id="arrivalDate"
            customInput={<CustomDatePicker />}
            dateFormat="MMMM d, yyyy"
            selected={formData.arrivalDate}
            selectsStart
            startDate={formData.arrivalDate}
            endDate={formData.departureDate}
            onChange={date => {
              setFormData(prev => ({ ...prev, arrivalDate: date }));
            }}
          />
        </div>
        <div className="flex flex-col gap-2 col-span-2">
          <label htmlFor="departureDate" className="text-neutral-500 text-xs">
            Departure Date
          </label>
          <DatePicker
            customInput={<CustomDatePicker />}
            dateFormat="MMMM d, yyyy"
            selected={formData.departureDate}
            selectsEnd
            startDate={formData.arrivalDate}
            endDate={formData.departureDate}
            minDate={formData.arrivalDate}
            onChange={date => {
              setFormData(prev => ({ ...prev, departureDate: date }));
            }}
          />
        </div>
      </div>
      <div className="mt-8 flex flex-col gap-6">
        {multipleChoice?.map((item, index) => (
          <fieldset key={index} className="flex flex-col gap-1">
            <legend className="font-medium text-neutral-600">
              {item.question}
            </legend>
            <div className="mt-1">
              {item.answers.map((answer, index) => (
                <div key={index}>
                  <input
                    type="radio"
                    name={item.question}
                    id={answer}
                    value={answer}
                    className="mr-2"
                    onChange={handleMultipleChoice}
                  />
                  <label htmlFor={answer} className="text-neutral-600">
                    {answer}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
        ))}
      </div>
    </div>
  );
};

const SecondPage = () => {
  const [formData, setFormData] = useState([
    {
      category: "Reception and Service",
      questions: [
        {
          question: "Courtesy and friendliness of reception/concierge staff",
          rate: null,
        },
        {
          question: "Check-in process",
          rate: null,
        },
        {
          question: "Check-out process",
          rate: null,
        },
      ],
      comments: "",
    },
    {
      category: "Guest Room",
      questions: [
        {
          question: "Room cleanliness",
          rate: null,
        },
        {
          question: "Bathroom cleanliness",
          rate: null,
        },
        {
          question: "Amenities",
          rate: null,
        },
        {
          question: "Quality of sleep",
          rate: null,
        },
        {
          question: "Was everything in working order in your room?",
          trueFalse: true,
          message: "",
        },
      ],
      comments: "",
    },
  ]);

  const handleRatingClick = (category, question) => rating => {
    setFormData(prev => {
      return prev.map(item => {
        if (item.category === category) {
          return {
            ...item,
            questions: item.questions.map(q => {
              if (q.question === question) {
                return { ...q, rate: rating };
              }
              return q;
            }),
          };
        }
        return item;
      });
    });
  };

  const handleTrueFalse = (category, question) => e => {
    setFormData(prev => {
      return prev.map(item => {
        if (item.category === category) {
          return {
            ...item,
            questions: item.questions.map(q => {
              if (q.question === question) {
                if (e.target.nodeName === "INPUT")
                  return {
                    ...q,
                    trueFalse: e.target.id === "true",
                  };
                if (e.target.nodeName === "TEXTAREA")
                  return {
                    ...q,
                    message: e.target.value,
                  };
              }
              return q;
            }),
          };
        }
        return item;
      });
    });
  };

  const handleComment = category => e => {
    setFormData(prev => {
      return prev.map(item => {
        if (item.category === category) {
          return { ...item, comments: e.target.value };
        }
        return item;
      });
    });
  };

  return (
    <div className="py-6">
      <ScrollRestoration />

      <p className="text-neutral-500">
        On a scale of 1 (low) to 5 (high), please rate the service that you
        received
      </p>
      <div className="flex flex-col gap-6">
        {formData?.map((item, index) => (
          <div key={index} className="mt-4 flex flex-col gap-1">
            <h2 className="text-neutral-600 font-medium border-b-[1px] border-deepOrange-700 pb-2">
              {item.category}
            </h2>
            <div className="mt-3 flex flex-col gap-4">
              {item.questions.map((question, index) => (
                <div key={index} className="flex flex-col gap-3">
                  <p className="text-neutral-500">{question.question}</p>
                  {question.hasOwnProperty("trueFalse") && (
                    <TrueFalseButtons
                      question={question}
                      messageTitle="If you checked 'no' what was not working?"
                      handleChange={handleTrueFalse(
                        item.category,
                        question.question
                      )}
                    />
                  )}
                  {question.hasOwnProperty("rate") && (
                    <RatingButtons
                      question={question}
                      handleClick={handleRatingClick(
                        item.category,
                        question.question
                      )}
                    />
                  )}
                </div>
              ))}
              {item.hasOwnProperty("comments") && (
                <div className="flex flex-col gap-2">
                  <label htmlFor="comments" className="text-neutral-500">
                    Comments: (optional)
                  </label>
                  <textarea
                    className="border-[1px] border-neutral-200 rounded-md px-2 py-1 outline-none  h-20 max-h-20 resize-none"
                    maxLength="20"
                    onChange={handleComment(item.category)}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const ThirdPage = () => {
  const [formData, setFormData] = useState([
    {
      category: "Breakfast",
      questions: [
        {
          question: "Quality of food",
          rate: null,
        },
        {
          question: "Food Variety",
          rate: null,
        },
        {
          question: "Service",
          rate: null,
        },
      ],
      comments: "",
    },
    {
      category: "Lunch / Dinner",
      questions: [
        {
          question: "Quality of food",
          rate: null,
        },
        {
          question: "Food variety",
          rate: null,
        },
        {
          question: "Service",
          rate: null,
        },
      ],
    },
    {
      category: "Room Service",
      questions: [
        { question: "Quality of food", rate: null },
        { question: "Service", rate: null },
      ],
      comments: "",
    },
  ]);

  return (
    <div className="py-6">
      <ScrollRestoration />

      <p className="text-neutral-500">
        On a scale of 1 (low) to 5 (high), please rate the service that you
        received
      </p>
      <div className="flex flex-col gap-6">
        {formData?.map((item, index) => (
          <div key={index} className="mt-4 flex flex-col gap-1">
            <h2 className="text-neutral-600 font-medium border-b-[1px] border-deepOrange-700 pb-2">
              {item.category}
            </h2>
            <div className="mt-3 flex flex-col gap-4">
              {item.questions.map((item, index) => (
                <div key={index} className="flex flex-col gap-3">
                  <p className="text-neutral-500">{item.question}</p>
                  {item.hasOwnProperty("trueFalse") && (
                    <TrueFalseButtons
                      hasMessage={item.hasOwnProperty("message")}
                      messageTitle="If you checked 'no' what was not working?"
                    />
                  )}
                  {item.hasOwnProperty("rate") && <RatingButtons />}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Utils

const RatingButtons = ({ question, handleClick }) => {
  // const totalRatings = 5;

  // const ratingButtons = [];

  // for (let i = 1; i <= totalRatings; i++) {
  //   ratingButtons.push(
  //     <button
  //       key={i}
  //       type="button"
  //       className={`p-1 w-7 h-7 text-sm rounded-full text-deepOrange-700 font-medium hover:bg-deepOrange-200 ${
  //         question.rate === i ? "bg-deepOrange-600" : "bg-deepOrange-100"
  //       }`}
  //       onClick={() => handleClick(i)}
  //     >
  //       {i}
  //     </button>
  //   );
  // }

  return (
    <div className="w-full flex gap-2 justify-evenly">
      <button
        type="button"
        className={`${
          question.rate === 1
            ? "bg-deepOrange-600 text-deepOrange-100"
            : "bg-deepOrange-100 text-deepOrange-700"
        } w-6 h-6 rounded-full`}
        onClick={() => handleClick(1)}
      >
        1
      </button>
      <button
        type="button"
        className={`${
          question.rate === 2
            ? "bg-deepOrange-600 text-deepOrange-100"
            : "bg-deepOrange-100 text-deepOrange-700"
        } w-6 h-6 rounded-full`}
        onClick={() => handleClick(2)}
      >
        2
      </button>
      <button
        type="button"
        className={`${
          question.rate === 3
            ? "bg-deepOrange-600 text-deepOrange-100"
            : "bg-deepOrange-100 text-deepOrange-700"
        } w-6 h-6 rounded-full`}
        onClick={() => handleClick(3)}
      >
        3
      </button>
      <button
        type="button"
        className={`${
          question.rate === 4
            ? "bg-deepOrange-600 text-deepOrange-100"
            : "bg-deepOrange-100 text-deepOrange-700"
        } w-6 h-6 rounded-full`}
        onClick={() => handleClick(4)}
      >
        4
      </button>
      <button
        type="button"
        className={`${
          question.rate === 5
            ? "bg-deepOrange-600 text-deepOrange-100"
            : "bg-deepOrange-100 text-deepOrange-700"
        } w-6 h-6 rounded-full`}
        onClick={() => handleClick(5)}
      >
        5
      </button>
    </div>
  );
};

const TrueFalseButtons = ({
  question,
  messageTitle = "Please specify why:",
  handleChange,
}) => {
  return (
    <div>
      <div className="flex justify-evenly">
        <div className="flex gap-2">
          <input
            type="radio"
            name="trueFalse"
            id="true"
            checked={question.trueFalse}
            onChange={handleChange}
          />
          <label htmlFor="true">True</label>
        </div>
        <div className="flex gap-2">
          <input
            type="radio"
            name="trueFalse"
            id="false"
            checked={!question.trueFalse}
            onChange={handleChange}
          />
          <label htmlFor="false">False</label>
        </div>
      </div>
      {question.hasOwnProperty("message") && (
        <div className="w-full flex flex-col gap-2 mt-2 px-4 mt-4">
          <label htmlFor="message" className="text-sm text-neutral-500">
            {messageTitle}
          </label>
          <textarea
            id="message"
            className="w-full px-2 py-1 border-[1px] border-neutral-200 rounded-md outline-none"
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
};
