import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

// Components
import Layout from "../components/Layout";
import Card from "../components/room-service/Card";

// Icons
import { VscChevronRight } from "react-icons/vsc";

// Data
import drinks from "../data/drinks.json";
import food from "../data/food.json";

const RoomServicePage = () => {
  const [menu, setMenu] = useState(drinks);
  const [underline, setUnderline] = useState();

  const btnRef = useRef();

  useEffect(() => {
    const getUnderlineValues = () => {
      if (btnRef.current) {
        setUnderline({
          left: 20,
          width: 46,
          // left: btnRef.current.offsetLeft,
          // width: btnRef.current.offsetWidth,
        });
      }
    };

    getUnderlineValues();

    // window.addEventListener("load", () => {
    //   getUnderlineValues();
    // });

    // return () => {
    //   window.removeEventListener("load", getUnderlineValues());
    // };
  }, [btnRef]);

  const handleClick = newList => e => {
    setMenu(newList);

    console.log(e.target.getClientRects()[0]);
    setUnderline({
      left: e.target.getClientRects()[0].left,
      width:
        e.target.getClientRects()[0].right - e.target.getClientRects()[0].left,
    });
  };

  return (
    <Layout title="Room Service">
      <div className="text-sm px-4 py-3 text-neutral-600 flex gap-1 items-center">
        <Link to="/">
          <p className="hover:underline hover:text-deepOrange-700">Home</p>
        </Link>
        <VscChevronRight />
        <p className="font-medium">Room Service</p>
      </div>
      <div className="pb-10">
        {/* Search Bar */}
        <main className="mt-8">
          <div className="flex justify-between mb-4 px-4 relative">
            <div className="px-1 flex gap-6">
              <button
                ref={btnRef}
                className="text-sm font-medium"
                onClick={handleClick(drinks)}
              >
                Drinks
              </button>

              <button
                className="text-sm font-medium"
                onClick={handleClick(food)}
              >
                Food
              </button>
            </div>
            {underline && (
              <div
                className={`absolute -bottom-1 h-[2px] bg-deepOrange-700 rounded-full transition-all duration-500`}
                style={{ width: underline.width, left: underline.left }}
              ></div>
            )}
          </div>
          <MenuList menu={menu} />
        </main>
      </div>
    </Layout>
  );
};

export default RoomServicePage;

const MenuList = ({ menu }) => {
  const [subcategories, setSubcategories] = useState();

  const getSubcategories = drinks => {
    const subcategories = [];
    drinks.map(drink => {
      if (subcategories.includes(drink.subcategory)) return;
      else subcategories.push(drink.subcategory);

      setSubcategories(subcategories);
    });
  };

  useEffect(() => {
    getSubcategories(menu);
  }, [menu]);

  return (
    <div className="px-4">
      {subcategories &&
        drinks &&
        subcategories.map((subcategory, index) => (
          <div key={index} className="py-4">
            <h3 className="capitalize font-medium text-neutral-400 text-sm">
              {subcategory}
            </h3>
            <div className="mt-2 grid md:grid-cols-2 xl:grid-cols-3 gap-3">
              {menu
                .filter(drink => drink.subcategory === subcategory)
                .map((drink, index) => (
                  <Card key={index} menu={drink} />
                ))}
            </div>
          </div>
        ))}
    </div>
  );
};
