const Ratings = ({ questionData, formData, onChange }) => {
  const { id, question } = questionData;
  return (
    <div>
      <h3>{question}</h3>
      <div className="mt-2 w-full flex gap-2 justify-evenly">
        <button
          type="button"
          className={`${
            formData[id] === 1
              ? "bg-deepOrange-600 text-deepOrange-100"
              : "text-deepOrange-700 border-[1px] border-deepOrange-700"
          } w-6 h-6 rounded-full`}
          onClick={onChange(id, 1)}
        >
          1
        </button>
        <button
          type="button"
          className={`${
            formData[id] === 2
              ? "bg-deepOrange-600 text-deepOrange-100"
              : "text-deepOrange-700 border-[1px] border-deepOrange-700"
          } w-6 h-6 rounded-full`}
          onClick={onChange(id, 2)}
        >
          2
        </button>
        <button
          type="button"
          className={`${
            formData[id] === 3
              ? "bg-deepOrange-600 text-deepOrange-100"
              : "text-deepOrange-700 border-[1px] border-deepOrange-700"
          } w-6 h-6 rounded-full`}
          onClick={onChange(id, 3)}
        >
          3
        </button>
        <button
          type="button"
          className={`${
            formData[id] === 4
              ? "bg-deepOrange-600 text-deepOrange-100"
              : "text-deepOrange-700 border-[1px] border-deepOrange-700"
          } w-6 h-6 rounded-full`}
          onClick={onChange(id, 4)}
        >
          4
        </button>
        <button
          type="button"
          className={`${
            formData[id] === 5
              ? "bg-deepOrange-600 text-deepOrange-100"
              : "text-deepOrange-700 border-[1px] border-deepOrange-700"
          } w-6 h-6 rounded-full`}
          onClick={onChange(id, 5)}
        >
          5
        </button>
      </div>
    </div>
  );
};

export default Ratings;
