import { useContext } from "react";

// Context
import GuestQuestionnaireContext from "../../context/GuestQuestionnaireContext";

const Pagination = () => {
  const { currentSection } = useContext(GuestQuestionnaireContext);
  return (
    <div className="flex gap-2 items-center absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
      <p
        className={`${
          currentSection === 0
            ? "text-deepOrange-100 bg-deepOrange-600"
            : "text-deepOrange-600 bg-deepOrange-100"
        } text-xs w-5 h-5 rounded-full flex justify-center items-center transition-colors duration-500`}
      >
        1
      </p>
      <p
        className={`${
          currentSection === 1
            ? "text-deepOrange-100 bg-deepOrange-600"
            : "text-deepOrange-600 bg-deepOrange-100"
        } text-xs w-5 h-5 rounded-full flex justify-center items-center transition-colors duration-500`}
      >
        2
      </p>
      <p
        className={`${
          currentSection === 2
            ? "text-deepOrange-100 bg-deepOrange-600"
            : "text-deepOrange-600 bg-deepOrange-100"
        } text-xs w-5 h-5 rounded-full flex justify-center items-center transition-colors duration-500`}
      >
        3
      </p>
      <p
        className={`${
          currentSection === 3
            ? "text-deepOrange-100 bg-deepOrange-600"
            : "text-deepOrange-600 bg-deepOrange-100"
        } text-xs w-5 h-5 rounded-full flex justify-center items-center transition-colors duration-500`}
      >
        4
      </p>
    </div>
  );
};

export default Pagination;
