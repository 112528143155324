const MultipleChoice = ({ questionData, formData, onChange }) => {
  const { id, question, answers } = questionData;

  return (
    <fieldset className="flex flex-col gap-1">
      <legend className="font-medium text-neutral-600">{question}</legend>
      <div className="mt-1">
        {answers.map(answer => (
          <div key={answer} className="flex gap-2">
            <input
              id={answer}
              name={id}
              type="radio"
              value={answer}
              checked={formData[id] === answer}
              onChange={onChange(id, answer)}
            />
            <label htmlFor={answer} className="text-neutral-600">
              {answer}
            </label>
          </div>
        ))}
      </div>
      <div className="flex gap-2">
        <input
          id={"other" + id}
          name={id}
          type="radio"
          checked={
            (formData[id] === "other" ||
              answers.every(x => x !== formData[id])) &&
            formData[id] !== ""
          }
          onChange={onChange(id, "other")}
        />
        <label htmlFor={"other" + id} className="text-neutral-600">
          Other
        </label>
        {answers.every(x => x !== formData[id]) && formData[id] !== "" && (
          <input
            id={"other" + id}
            name={id}
            className="border-[1px] rounded-sm w-full px-2 outline-none"
            placeholder="Please specify"
            value={formData[id] !== "other" ? formData[id] : ""}
            onChange={onChange(id, undefined)}
          />
        )}
      </div>
    </fieldset>
  );
};

export default MultipleChoice;
