// Components
import { Link, useNavigate } from "react-router-dom";
import Button from "./shared/Button";

// Icons
import { VscListSelection, VscGlobe, VscClose } from "react-icons/vsc";
import { VscFeedback, VscHome } from "react-icons/vsc";
import {
  PiCallBellLight,
  PiBuildingsLight,
  PiBookOpenTextLight,
  PiBagLight,
} from "react-icons/pi";

const Navbar = () => {
  return (
    <div className="bg-[#fafafa] border-b-[1px]">
      <div className="px-2 py-2 flex justify-center items-center">
        {/* <Button variant="text" rounded>
          <VscListSelection className="text-lg text-neutral-600" />
        </Button> */}
        <img
          src="/images/logo-black.svg"
          alt="Holiday Garden Hotel Logo"
          className="w-24 mr-3"
        />
        {/* <Button variant="text" rounded>
          <VscGlobe className="text-lg text-neutral-600" />
        </Button> */}
      </div>
      {/* <Modal /> */}
    </div>
  );
};

export default Navbar;

const Modal = () => {
  return (
    <div className="fixed inset-0 z-10 bg-neutral-800/40">
      <div className="bg-[#fafafa] w-full drop-shadow-lg pt-4 pb-6 px-4">
        <div className="flex justify-end">
          <Button rounded variant="text">
            <VscClose className="text-xl text-neutral-600" />
          </Button>
        </div>
        <nav className="mt-2 flex flex-col gap-4 text-lg font-medium text-deepOrange-700">
          <Link to="/">
            <Button expand variant="ghost" icon={<VscHome />}>
              Home
            </Button>
          </Link>
          <Link to="/hotel-directory">
            <Button expand variant="ghost" icon={<PiBookOpenTextLight />}>
              Directory
            </Button>
          </Link>
          <Link to="/room-service">
            <Button expand variant="ghost" icon={<PiCallBellLight />}>
              Room Service
            </Button>
          </Link>
          <Link to="/mini-story">
            <Button expand variant="ghost" icon={<PiBagLight />}>
              Mini Store
            </Button>
          </Link>
          <Link to="/contact-information">
            <Button expand variant="ghost" icon={<VscFeedback />}>
              Contact Information
            </Button>
          </Link>
        </nav>
      </div>
    </div>
  );
};
