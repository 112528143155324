import { createContext, useState } from "react";

import { useNavigate } from "react-router-dom";

// Toast
import { toast } from "react-toastify";

const GuestQuestionnaireContext = createContext();

export const GuestQuestionnaireProvider = ({ children }) => {
  const [currentSection, setCurrentSection] = useState(0);
  const [completeFormData, setCompleteFormData] = useState({
    1: null,
    2: null,
    3: null,
    4: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const prevSection = () => setCurrentSection(prev => --prev);
  const nextSection = () => setCurrentSection(prev => ++prev);

  const addData = (sectionNo, formData) => {
    setCompleteFormData(prev => ({ ...prev, [sectionNo]: formData }));
  };

  const submitQuestionnaire = async (sectionNo, formData) => {
    const data = { ...completeFormData, [sectionNo]: formData };

    const isComplete = Object.values(data).every(x => x !== null);
    const valuesAreValid = obj => {
      for (const key in obj) {
        const value = obj[key];
        if (typeof value === "object" && value !== null) {
          if (!valuesAreValid(value)) {
            return false;
          }
        } else if (
          !(
            (typeof value === "string" && value.length <= 200) ||
            (typeof value === "number" && value <= 10) ||
            typeof value === "boolean" ||
            value === "" ||
            value === null
          )
        )
          return false;
      }
      return true;
    };

    if (!isComplete) {
      toast.error(
        "Sorry you did not complete every the questionnaire. Please go back"
      );
      return;
    }

    if (!valuesAreValid(data)) {
      toast.error("Sorry your data seems to be invalid.");
      return;
    }

    setIsLoading(true);

    async function submitHandler(data) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/questionnaire`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          toast.error(errorData.message);
          // throw new Error(errorData.message);
        } else {
          const responseData = await response.json(); // Parse the JSON response

          toast.success(responseData.message);
          setTimeout(() => {
            setIsLoading(false);
            navigate("/");
          }, [2000]);
        }
      } catch (error) {
        toast.error("From front end. Sorry, could not submit.");
        console.error("Error:", error);
      }
    }

    // Check Recaptcha first
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
          action: "submit",
        })
        .then(async token => {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/recaptcha`,
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ token }),
              }
            );

            if (response.ok) {
              const successText = await response.text();
              console.log(successText);
              submitHandler(data);
            } else {
              const errorText = await response.text();
              console.log(errorText);
            }
          } catch (error) {
            console.log(error);
          }
        });
    });
  };

  return (
    <GuestQuestionnaireContext.Provider
      value={{
        currentSection,
        completeFormData,
        isLoading,
        setCurrentSection,
        prevSection,
        nextSection,
        addData,
        submitQuestionnaire,
      }}
    >
      {children}
    </GuestQuestionnaireContext.Provider>
  );
};

export default GuestQuestionnaireContext;
