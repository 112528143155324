// Icons
import { PiCoffeeLight } from "react-icons/pi";
import { BsCupStraw } from "react-icons/bs";

const Card = ({ menu, objectFit }) => {
  const { title, description, price, imageUrl } = menu;

  const displayPrice = price => {
    if (typeof price === "number")
      return <p className="mt-1 text-neutral-700 font-medium">{price}.-</p>;
    else if (typeof price === "object")
      return (
        <div className="flex gap-3">
          {price.hot && (
            <p className="flex gap-[6px] items-center font-medium text-neutral-600">
              <PiCoffeeLight className="mb-[2px] text-deepOrange-700" />
              {price.hot}.-
            </p>
          )}
          {price.cold && (
            <p className="flex gap-1 items-center font-medium text-neutral-600">
              <BsCupStraw className="mb-[2px] text-sky-600" />
              {price.cold}.-
            </p>
          )}
        </div>
      );
  };

  return (
    <div className="flex items-center gap-3 p-2 bg-white drop-shadow-sm rounded-[12px] ">
      <div className="aspect-[5/4] w-32 rounded-lg overflow-hidden shrink-0">
        <img
          src={imageUrl}
          alt="food name"
          className={`w-full h-full ${objectFit}`}
        />
      </div>
      <div className="py-1 flex flex-col">
        <h2 className="text-neutral-700 font-medium capitalize">{title}</h2>
        <p className="text-xs text-neutral-500 mt-[2px] mb-1">{description}</p>
        {displayPrice(price)}
      </div>
    </div>
  );
};

export default Card;

Card.defaultProps = {
  objectFit: "object-contain",
};
