import { useContext, useEffect, useState } from "react";

// React-router-dom
import { ScrollRestoration } from "react-router-dom";

// Context
import GuestQuestionnaireContext from "../../context/GuestQuestionnaireContext";

// Component
import Button from "../shared/Button";
import Pagination from "./Pagination";
import Ratings from "./shared/Ratings";

// Toast
import { toast } from "react-toastify";

const questions = [
  {
    id: "c1",
    type: "rating",
    category: "breakfast",
    question: "Quality of food",
  },
  {
    id: "c2",
    type: "rating",
    category: "breakfast",
    question: "Food variety",
  },
  {
    id: "c3",
    type: "rating",
    category: "breakfast",
    question: "Service",
  },
  {
    id: "c4",
    type: "rating",
    category: "room service",
    question: "Food Quality",
  },
  {
    id: "c5",
    type: "rating",
    category: "room service",
    question: "Service",
  },
  {
    id: "c6",
    category: "lunch/dinner",
    question: "Dining room",
    answers: ["Huay Kaew", "Mae Sa Restaurant"],
  },
  {
    id: "c7",
    type: "rating",
    category: "lunch/dinner",
    question: "Quality of food",
  },
  {
    id: "c8",
    type: "rating",
    category: "lunch/dinner",
    question: "Food variety",
  },
  {
    id: "c9",
    type: "rating",
    category: "lunch/dinner",
    question: "Service",
  },
];

const SectionThree = () => {
  const { completeFormData, prevSection, nextSection, addData } = useContext(
    GuestQuestionnaireContext
  );

  const [formData, setFormData] = useState();
  const [didNotHave, setDidNotHave] = useState([]);

  useEffect(() => {
    if (completeFormData["3"]) {
      setFormData(completeFormData["3"]);
    } else
      setFormData({
        c1: null,
        c2: null,
        c3: null,
        c4: null,
        c5: null,
        c6: null,
        c7: null,
        c8: null,
        c9: null,
      });
  }, [completeFormData]);

  const handleChange = (id, answer) => e => {
    setFormData(prev => {
      // Rating button
      if (e.target.nodeName === "BUTTON") {
        return { ...prev, [id]: answer };
      }
    });
  };

  const handleDiningRoom = e => {
    setFormData(prev => ({ ...prev, c6: e.target.id }));
  };

  const handleDidNotHave = e => {
    setDidNotHave(prev => {
      const indexOfValue = prev.indexOf(e.target.id);

      if (indexOfValue !== -1) {
        // Create a new array without the element to be removed
        const newArray = [...prev];
        newArray.splice(indexOfValue, 1);
        return newArray;
      } else {
        questions.forEach(question => {
          if (question.category === e.target.id) {
            setFormData(prev => ({ ...prev, [question.id]: null }));
          }
        });
        // Add the element to the array
        return [...prev, e.target.id];
      }
    });
  };

  const handlePrevBtn = () => {
    prevSection();
  };

  const handleNextBtn = () => {
    const getNotRequired = () => {
      let notRequired = [];
      questions.map(question => {
        if (didNotHave.includes(question.category))
          notRequired.push(question.id);
        else return;
      });
      return notRequired;
    };

    const isFilled = prop =>
      getNotRequired().includes(prop) ||
      (formData[prop] !== null && formData[prop] !== "");

    const allFilled = Object.keys(formData).every(x => isFilled(x));

    if (!allFilled) {
      toast.error("Please fill everything");
      return;
    }

    addData(3, formData);
    nextSection();
  };

  return (
    <div className="py-8">
      <ScrollRestoration />
      <div className="flex flex-col gap-6">
        {/* Breakfast */}
        <div>
          <div className="border-b-[1px] border-deepOrange-700 pb-2 flex justify-between">
            <h2 className="text-neutral-600 font-medium">Breakfast</h2>
            <div className="flex gap-1 items-center">
              <input
                id="breakfast"
                type="checkbox"
                onChange={handleDidNotHave}
              />
              <label htmlFor="breakfast" className="text-sm">
                Did not have
              </label>
            </div>
          </div>
          {!didNotHave.includes("breakfast") && (
            <div className="py-4 flex flex-col gap-3">
              {formData &&
                questions
                  .filter(q => q.category === "breakfast")
                  .map(question => {
                    if (question.type === "rating")
                      return (
                        <Ratings
                          key={question.id}
                          questionData={question}
                          formData={formData}
                          onChange={handleChange}
                        />
                      );
                  })}
            </div>
          )}
        </div>
        {/* Room Service */}
        <div>
          <div className="border-b-[1px] border-deepOrange-700 pb-2 flex justify-between">
            <h2 className="text-neutral-600 font-medium">Room Service</h2>
            <div className="flex gap-1 items-center">
              <input
                id="room service"
                type="checkbox"
                onChange={handleDidNotHave}
              />
              <label htmlFor="room service" className="text-sm">
                Did not have
              </label>
            </div>
          </div>
          {!didNotHave.includes("room service") && (
            <div className="py-4 flex flex-col gap-3">
              {formData &&
                questions
                  .filter(q => q.category === "room service")
                  .map(question => {
                    if (question.type === "rating")
                      return (
                        <Ratings
                          key={question.id}
                          questionData={question}
                          formData={formData}
                          onChange={handleChange}
                        />
                      );
                  })}
            </div>
          )}
        </div>
        {/* Lunch / Dinner */}
        <div>
          <div className="border-b-[1px] border-deepOrange-700 pb-2 flex justify-between">
            <h2 className="text-neutral-600 font-medium">Lunch / Dinner</h2>
            <div className="flex gap-1 items-center">
              <input
                id="lunch/dinner"
                type="checkbox"
                onChange={handleDidNotHave}
              />
              <label htmlFor="lunch/dinner" className="text-sm">
                Did not have
              </label>
            </div>
          </div>
          {!didNotHave.includes("lunch/dinner") && (
            <div className="py-4 flex flex-col gap-3">
              {formData && (
                <div>
                  <h3>Dining room</h3>
                  <div className="flex justify-evenly">
                    <div className="flex gap-1">
                      <input
                        id="Huay Kaew"
                        type="radio"
                        name="diningRoom"
                        checked={formData["c6"] === "Huay Kaew"}
                        onChange={handleDiningRoom}
                      />
                      <label htmlFor="Huay Kaew">Huay Kaew</label>
                    </div>
                    <div className="flex gap-1">
                      <input
                        id="Mae Sa Restaurant"
                        type="radio"
                        name="diningRoom"
                        checked={formData["c6"] === "Mae Sa Restaurant"}
                        onChange={handleDiningRoom}
                      />
                      <label htmlFor="Mae Sa Restaurant">
                        Mae Sa Restaurant
                      </label>
                    </div>
                  </div>
                </div>
              )}
              {formData &&
                questions
                  .filter(q => q.category === "lunch/dinner")
                  .map(question => {
                    if (question.type === "rating")
                      return (
                        <Ratings
                          key={question.id}
                          questionData={question}
                          formData={formData}
                          onChange={handleChange}
                        />
                      );
                  })}
            </div>
          )}
        </div>
      </div>
      {/* Footer */}
      <div className="relative h-8 my-8">
        <div className="absolute left-0" onClick={handlePrevBtn}>
          <Button variant="text">
            <span className="flex items-center text-deepOrange-600 text-sm">
              Previous
            </span>
          </Button>
        </div>
        <Pagination />
        <div className="absolute right-0" onClick={handleNextBtn}>
          <Button variant="text">
            <span className="flex items-center text-deepOrange-600 text-sm">
              Next
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
