const TrueAndFalse = ({ questionData, formData, onChange }) => {
  const { id, question } = questionData;

  return (
    <div>
      <h3>{question}</h3>
      <div className="mt-2 w-full flex gap-2 justify-evenly">
        <div className="flex gap-2">
          <input
            id={`${id}yes`}
            name={id}
            type="radio"
            checked={formData[id] === true}
            onChange={onChange(id, true)}
          />
          <label htmlFor={`${id}yes`}>Yes</label>
        </div>
        <div className="flex gap-2">
          <input
            id={`${id}no`}
            name={id}
            type="radio"
            checked={formData[id] === false}
            onChange={onChange(id, false)}
          />
          <label htmlFor={`${id}no`}>No</label>
        </div>
      </div>
    </div>
  );
};

export default TrueAndFalse;
