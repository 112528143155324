const Button = ({
  variant,
  rounded,
  type,
  icon,
  expand,
  handleClick,
  children,
}) => {
  const colorSchemeOptions = {
    contained:
      "bg-deepOrange-600 text-deepOrange-100 hover:bg-deepOrange-700 transition duration-150",
    text: "bg-none text-neutral-700 hover:bg-neutral-100",
    ghost:
      "bg-deepOrange-100 text-deepOrange-700 hover:bg-deepOrange-200 drop-shadow-sm",
    outlined: "border-[1px] border-deepOrange-500 text-deepOrange-700",
  };
  return (
    <button
      type={type}
      className={`${colorSchemeOptions[variant]} ${
        rounded ? "rounded-full p-2 w-8 h-8 text-sm" : "rounded-[4px] px-4 py-2"
      } ${
        expand ? "w-full" : ""
      } flex justify-center items-center gap-2 drop-shadow-sm drop-shadow-sm`}
      onClick={handleClick ? handleClick : () => {}}
    >
      {icon && <span>{icon}</span>}
      <span className="whitespace-nowrap">{children}</span>
    </button>
  );
};

export default Button;

Button.defaultProps = {
  variant: "contained",
  rounded: false,
  type: "button",
  children: "button",
  expand: false,
  icon: "",
};
