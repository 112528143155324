import { useContext, useEffect, useState } from "react";

// React-router-dom
import { ScrollRestoration } from "react-router-dom";

// Context
import GuestQuestionnaireContext from "../../context/GuestQuestionnaireContext";

// Component
import Button from "../shared/Button";
import Pagination from "./Pagination";
import Ratings from "./shared/Ratings";
import TrueAndFalse from "./shared/TrueAndFalse";
import Comment from "./shared/Comment";

// Icons
import { VscLoading } from "react-icons/vsc";

// Toast
import { toast } from "react-toastify";

const questions = [
  {
    id: "d1",
    type: "rating",
    question: "How would you rate your overall experience?",
  },
  {
    id: "d2",
    type: "trueAndFalse",
    question: "Would you stay with us again?",
  },
  {
    id: "d3",
    type: "trueAndFalse",
    question: "Would you recommend our hotel to family and friends?",
  },
  { id: "d4", type: "comment" },
];

const SectionFour = () => {
  const { completeFormData, prevSection, isLoading, submitQuestionnaire } =
    useContext(GuestQuestionnaireContext);

  const [formData, setFormData] = useState();

  useEffect(() => {
    if (completeFormData["4"]) {
      setFormData(completeFormData["4"]);
    } else
      setFormData({
        d1: null,
        d2: null,
        d3: null,
        d4: "",
      });
  }, [completeFormData]);

  const optional = ["d4"];

  const handleChange = (id, answer) => e => {
    setFormData(prev => {
      // Comment textarea
      if (e.target.nodeName === "TEXTAREA") {
        return { ...prev, [e.target.id]: e.target.value };
      }

      // Rating button
      if (e.target.nodeName === "BUTTON") {
        return { ...prev, [id]: answer };
      }

      // True and False radio
      if (e.target.type === "radio") {
        return { ...prev, [id]: answer };
      }
    });
  };

  const handlePrevBtn = () => {
    prevSection();
  };

  const handleSubmit = () => {
    const isFilled = prop =>
      optional.includes(prop) ||
      (formData[prop] !== null && formData[prop] !== "");

    const allFilled = Object.keys(formData).every(x => isFilled(x));

    if (!allFilled) {
      toast.error("Please fill everything");
      return;
    }

    submitQuestionnaire(4, formData);
  };

  return (
    <div className="py-8">
      <ScrollRestoration />
      <div className="flex flex-col gap-6">
        <div className="mt-4 flex flex-col gap-1">
          <h2 className="text-neutral-600 font-medium border-b-[1px] border-deepOrange-700 pb-2">
            Lastly
          </h2>
          <div className="py-4 flex flex-col gap-3">
            {formData &&
              questions.map(question => {
                if (question.type === "rating")
                  return (
                    <Ratings
                      key={question.id}
                      questionData={question}
                      formData={formData}
                      onChange={handleChange}
                    />
                  );
                if (question.type === "trueAndFalse")
                  return (
                    <TrueAndFalse
                      key={question.id}
                      questionData={question}
                      formData={formData}
                      onChange={handleChange}
                    />
                  );
                if (question.type === "comment")
                  return (
                    <Comment
                      key={question.id}
                      questionData={question}
                      formData={formData}
                      onChange={handleChange}
                    />
                  );
              })}
          </div>
        </div>
      </div>
      <div className="relative h-8 my-8">
        <div className="absolute left-0" onClick={handlePrevBtn}>
          <Button variant="text">
            <span className="flex items-center text-deepOrange-600 text-sm">
              Previous
            </span>
          </Button>
        </div>
        <Pagination />
      </div>
      <div className="text-gray-800 text-sm text-center">
        This site is protected by reCAPTCHA and the Google{" "}
        <a
          className="text-blue-600 hover:underline"
          href="https://policies.google.com/privacy"
          _target="blank"
          rel="nofollow noreferrer"
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          className="text-blue-600 hover:underline"
          href="https://policies.google.com/terms"
          _target="blank"
          rel="nofollow noreferrer"
        >
          Terms of Service
        </a>{" "}
        apply.
      </div>
      <button
        type="button"
        className={`${
          isLoading
            ? "bg-deepOrange-700 text-deepOrange-200"
            : "bg-deepOrange-600 text-deepOrange-100"
        } w-full py-2 font-medium rounded-sm mt-8`}
        onClick={handleSubmit}
        // disabled={isLoading}
      >
        {isLoading ? (
          <span className="flex justify-center items-center gap-2">
            <VscLoading className="inline animate-spin" /> Submitting...
          </span>
        ) : (
          "Submit"
        )}
      </button>
    </div>
  );
};

export default SectionFour;
